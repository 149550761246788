define('frontend-cp/components/side-conversations-panel/individual-conversation/component', ['exports', 'ember-concurrency', 'frontend-cp/utils/format-validations', 'ember-keyboard-shortcuts/mixins/component'], function (exports, _emberConcurrency, _formatValidations, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var Component = Ember.Component;
  var observer = Ember.observer;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var isBlank = Ember.isBlank;
  var EmberObject = Ember.Object;
  exports.default = Component.extend(_component.default, {
    tagName: '',

    // Services
    store: service(),
    sessionService: service('session'),
    uploadService: service('fileUpload'),
    i18n: service(),
    notification: service(),

    // Attributes
    isNew: false,
    toEmails: [],
    ccEmails: [],
    emailContent: '',
    subject: '',
    errors: {},
    isSending: false,
    attachments: [],
    case: null,
    loadedConversation: null,
    upladingFiles: false,

    keyboardShortcuts: {
      'mod+enter': {
        action: 'send',
        global: true,
        preventDefault: true
      }
    },

    title: computed('currentConversation', 'loadedConversation', 'isNew', function () {
      if (this.get('isNew')) {
        return this.get('i18n').t('cases.side_conversations.new_conversation');
      } else {
        return this.get('currentConversation.subject') || this.get('loadedConversation.0.message.subject') || this.get('subject');
      }
    }),

    subtitle: computed('currentConversation', function () {
      return '';
    }),

    isSendDisabled: computed('toEmails', 'ccEmails', 'emailContent', 'subject', 'uploadingFiles', function () {
      var hasValidToEmails = this.get('toEmails').every(function (email) {
        return (0, _formatValidations.validateEmailFormat)(email);
      });
      var hasValidCCEmails = this.get('ccEmails').every(function (email) {
        return (0, _formatValidations.validateEmailFormat)(email);
      });
      var hasContent = !isBlank(this.get('emailContent'));
      var isUploadingFiles = this.get('uploadingFiles');

      if (this.get('isNew')) {
        var hasSubject = !isBlank(this.get('subject'));
        return !(hasValidToEmails && hasValidCCEmails && hasContent && hasSubject) || isUploadingFiles;
      } else {
        return !(hasValidToEmails && hasValidCCEmails && hasContent) || isUploadingFiles;
      }
    }),

    suggestPeople: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(address) {
      var mailboxAddresses, trimmedAddress, isAMailboxAddress, selectedPeople, idsToExclude, data, results, isValidEmail, enteredCcIsNotSelectedOrMailboxEmail, autocompleteResultIsNotTheSameAsEnteredCc;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              mailboxAddresses = this.get('store').peekAll('channel').filterBy('isChannelTypeMailbox').getEach('handle');
              trimmedAddress = address.trim();
              isAMailboxAddress = mailboxAddresses.includes(trimmedAddress);

              if (!(isBlank(trimmedAddress) || isAMailboxAddress)) {
                _context.next = 5;
                break;
              }

              return _context.abrupt('return', []);

            case 5:
              _context.next = 7;
              return (0, _emberConcurrency.timeout)(300);

            case 7:
              selectedPeople = [].concat(_toConsumableArray(this.get('toEmails')), _toConsumableArray(this.get('ccEmails')));
              idsToExclude = [].concat(_toConsumableArray(selectedPeople), _toConsumableArray(mailboxAddresses));
              _context.next = 11;
              return this.get('store').query('identity-autocomplete-email', {
                address: trimmedAddress
              });

            case 11:
              data = _context.sent;


              // Remove any already selected or mailbox emails from results list
              results = data.filter(function (autocomplete) {
                return !idsToExclude.includes(autocomplete.get('identity.email'));
              });
              isValidEmail = (0, _formatValidations.validateEmailFormat)(trimmedAddress);
              enteredCcIsNotSelectedOrMailboxEmail = !idsToExclude.includes(trimmedAddress);
              autocompleteResultIsNotTheSameAsEnteredCc = !results.mapBy('identity.email').includes(trimmedAddress);


              if (isValidEmail && enteredCcIsNotSelectedOrMailboxEmail && autocompleteResultIsNotTheSameAsEnteredCc) {
                results.unshift(EmberObject.create({
                  isNew: true,
                  identity: {
                    email: trimmedAddress
                  }
                }));
              }

              return _context.abrupt('return', results);

            case 18:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).restartable(),

    conversationIdObserver: observer('conversationId', _asyncToGenerator(regeneratorRuntime.mark(function _callee2() {
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              if (!this.get('conversationId')) {
                _context2.next = 3;
                break;
              }

              _context2.next = 3;
              return this.loadConversation(this.get('conversationId'));

            case 3:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    }))),

    currentConversationObserver: observer('currentConversation.id', _asyncToGenerator(regeneratorRuntime.mark(function _callee3() {
      return regeneratorRuntime.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              if (!this.get('currentConversation.id')) {
                _context3.next = 3;
                break;
              }

              _context3.next = 3;
              return this.loadConversation(this.get('currentConversation.id'));

            case 3:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3, this);
    }))),

    resetData: function resetData() {
      this.set('isNew', true);
      this.set('currentConversation', null);
      this.set('loadedConversation', null);
      this.set('toEmails', []);
      this.set('ccEmails', []);
      this.set('emailContent', '');
      this.set('subject', '');
      this.set('errors', {});
      this.set('attachments', []);
      this.set('isSending', false);
      this.set('uploadingFiles', false);
    },
    loadConversation: function () {
      var _ref3 = _asyncToGenerator(regeneratorRuntime.mark(function _callee4(conversationId, isReplying) {
        var _this = this;

        var sideConversationAdapter, sideConversation, lastMessage, toEmails, ccEmails, lastMessageCreatorEmail;
        return regeneratorRuntime.wrap(function _callee4$(_context4) {
          while (1) {
            switch (_context4.prev = _context4.next) {
              case 0:
                if (conversationId) {
                  _context4.next = 3;
                  break;
                }

                this.resetData();
                return _context4.abrupt('return');

              case 3:
                _context4.prev = 3;

                setTimeout(function () {
                  _this.get('setView')('INDIVIDUAL_CONVERSATION');
                  _this.set('isNew', false);
                });
                if (!isReplying) {
                  this.set('loadedConversation', null);
                  this.set('toEmails', []);
                  this.set('ccEmails', []);
                  this.set('subject', '');
                  this.set('emailContent', '');
                  this.set('attachments', []);
                }
                sideConversationAdapter = this.get('store').adapterFor('side-conversation');
                _context4.next = 9;
                return sideConversationAdapter.loadSideConversation(conversationId, { limit: 100 });

              case 9:
                sideConversation = _context4.sent;

                this.set('loadedConversation', sideConversation);

                lastMessage = sideConversation[sideConversation.length - 1].message;
                toEmails = lastMessage.recipients.filter(function (recipient) {
                  return recipient.type === 'TO';
                }).map(function (recipient) {
                  return recipient.identity.email;
                });
                ccEmails = lastMessage.recipients.filter(function (recipient) {
                  return recipient.type === 'CC';
                }).map(function (recipient) {
                  return recipient.identity.email;
                });


                if (toEmails.length === 0) {
                  lastMessageCreatorEmail = sideConversation[sideConversation.length - 1].message.creator.emails[0].email;

                  toEmails.push(lastMessageCreatorEmail);
                }

                setTimeout(function () {
                  _this.set('toEmails', toEmails);
                  _this.set('ccEmails', ccEmails);
                });
                _context4.next = 21;
                break;

              case 18:
                _context4.prev = 18;
                _context4.t0 = _context4['catch'](3);

                this.get('notification').error(this.get('i18n').t('generic.generic_error'));

              case 21:
              case 'end':
                return _context4.stop();
            }
          }
        }, _callee4, this, [[3, 18]]);
      }));

      function loadConversation(_x, _x2) {
        return _ref3.apply(this, arguments);
      }

      return loadConversation;
    }(),
    onUploadAttachments: function onUploadAttachments(files) {
      this.set('attachments', files);
    },


    actions: {
      cancelAttachment: function cancelAttachment(attachment) {
        this.set('attachments', this.get('attachments').filter(function (a) {
          return a.name !== attachment.name || a.size !== attachment.size;
        }));
      },
      send: function () {
        var _ref4 = _asyncToGenerator(regeneratorRuntime.mark(function _callee5(e) {
          var caseId, subject, contents, toEmails, ccEmails, attachmentFileIds, channelOptions, sideConversationAdapter, sideConversation;
          return regeneratorRuntime.wrap(function _callee5$(_context5) {
            while (1) {
              switch (_context5.prev = _context5.next) {
                case 0:
                  if (!this.get('isSendDisabled')) {
                    _context5.next = 2;
                    break;
                  }

                  return _context5.abrupt('return');

                case 2:
                  this.set('isSending', true);
                  this.set('isNew', false);
                  this.get('setView')('INDIVIDUAL_CONVERSATION');

                  caseId = this.get('case.id');
                  subject = this.get('subject');
                  contents = this.get('emailContent');
                  toEmails = this.get('toEmails');
                  ccEmails = this.get('ccEmails');
                  attachmentFileIds = this.get('attachments').map(function (file) {
                    return file.attachmentId;
                  }).filter(Boolean);
                  channelOptions = {
                    to: toEmails.join(',')
                  };


                  if (ccEmails.length > 0) {
                    channelOptions.cc = ccEmails.join(',');
                  }

                  sideConversationAdapter = this.get('store').adapterFor('side-conversation');


                  this.send('updateEmailContent', '');
                  this.set('attachments', []);

                  _context5.prev = 16;
                  sideConversation = void 0;

                  if (!this.get('currentConversation.id')) {
                    _context5.next = 26;
                    break;
                  }

                  _context5.next = 21;
                  return sideConversationAdapter.replyToSideConversation(this.get('currentConversation.id'), contents, channelOptions, attachmentFileIds);

                case 21:
                  sideConversation = _context5.sent;
                  _context5.next = 24;
                  return this.loadConversation(this.get('currentConversation.id'), true);

                case 24:
                  _context5.next = 31;
                  break;

                case 26:
                  _context5.next = 28;
                  return sideConversationAdapter.createNewSideConversation(caseId, subject, contents, channelOptions, attachmentFileIds);

                case 28:
                  sideConversation = _context5.sent;

                  this.set('currentConversation', sideConversation);
                  this.get('refetchConversations')(true);

                case 31:
                  _context5.next = 36;
                  break;

                case 33:
                  _context5.prev = 33;
                  _context5.t0 = _context5['catch'](16);

                  this.get('notification').error(this.get('i18n').t('generic.generic_error'));

                case 36:
                  _context5.prev = 36;

                  this.set('isSending', false);
                  this.set('ccEmails', []);
                  return _context5.finish(36);

                case 40:
                case 'end':
                  return _context5.stop();
              }
            }
          }, _callee5, this, [[16, 33, 36, 40]]);
        }));

        function send(_x3) {
          return _ref4.apply(this, arguments);
        }

        return send;
      }(),
      updateSubject: function updateSubject(subject) {
        this.set('subject', subject);
      },
      setToEmails: function setToEmails(emailList) {
        var uniqueEmails = Array.from(new Set(emailList.map(function (email) {
          if (typeof email === 'string') {
            return email;
          } else {
            return email.get('identity.email');
          }
        })));
        this.set('toEmails', uniqueEmails);
      },
      setCCEmails: function setCCEmails(emailList) {
        var uniqueEmails = Array.from(new Set(emailList.map(function (email) {
          if (typeof email === 'string') {
            return email;
          } else {
            return email.get('identity.email');
          }
        })));
        this.set('ccEmails', uniqueEmails);
      },
      updateEmailContent: function updateEmailContent(content) {
        this.set('emailContent', content);
      },
      onAttachFiles: function onAttachFiles(files) {
        var _this2 = this;

        files.forEach(function (file) {
          _this2.get('uploadService').get('uploadFile').perform(file, null, null, function (file) {
            _this2.set('uploadingFiles', true);
            _this2.onUploadAttachments([file]);
          }, function (file) {
            _this2.set('uploadingFiles', false);
            _this2.onUploadAttachments([file]);
          });
        });
      },
      reloadConversation: function () {
        var _ref5 = _asyncToGenerator(regeneratorRuntime.mark(function _callee6() {
          return regeneratorRuntime.wrap(function _callee6$(_context6) {
            while (1) {
              switch (_context6.prev = _context6.next) {
                case 0:
                  if (this.get('loadedConversation')) {
                    _context6.next = 2;
                    break;
                  }

                  return _context6.abrupt('return');

                case 2:
                  this.set('loadedConversation', null);
                  _context6.next = 5;
                  return this.loadConversation(this.get('currentConversation.id'));

                case 5:
                case 'end':
                  return _context6.stop();
              }
            }
          }, _callee6, this);
        }));

        function reloadConversation() {
          return _ref5.apply(this, arguments);
        }

        return reloadConversation;
      }(),
      onBack: function onBack() {
        this.get('setView')('CONVERSATIONS_LIST');
        this.resetData();
      }
    }
  });
});