define("frontend-cp/components/side-conversations-panel/message-timeline/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "message-timeline-item": "side-conversations-panel_message-timeline__message-timeline-item_r53ba9",
    "message-header": "side-conversations-panel_message-timeline__message-header_r53ba9",
    "avatar": "side-conversations-panel_message-timeline__avatar_r53ba9",
    "sender-info": "side-conversations-panel_message-timeline__sender-info_r53ba9",
    "sender-name": "side-conversations-panel_message-timeline__sender-name_r53ba9",
    "sender-email": "side-conversations-panel_message-timeline__sender-email_r53ba9",
    "message-timestamp": "side-conversations-panel_message-timeline__message-timestamp_r53ba9",
    "message-body": "side-conversations-panel_message-timeline__message-body_r53ba9",
    "message-attachments": "side-conversations-panel_message-timeline__message-attachments_r53ba9",
    "loader": "side-conversations-panel_message-timeline__loader_r53ba9",
    "message-recipients": "side-conversations-panel_message-timeline__message-recipients_r53ba9",
    "recipient-heading": "side-conversations-panel_message-timeline__recipient-heading_r53ba9",
    "recipient": "side-conversations-panel_message-timeline__recipient_r53ba9"
  };
});